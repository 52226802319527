body{
    font-family: 'Oswald', sans-serif !important;
}

code {
    font-family: 'Oswald', sans-serif !important;
}

.widget-slider {
    padding: 10px 0px;
    background-color: #000000;
}

.widget {
    width: 100%;
    display: block;
    text-align: left;
    cursor: pointer;
}

.widget-container {
    margin-bottom: 15px;
    align-items: unset !important;
    display: flex;
    flex-wrap: nowrap;
    font-family: 'Oswald', sans-serif !important;
    font-size: 14px;
    line-height: 1.4em;
    box-sizing: border-box;
}

/*Dynamic*/
.widget-footer {
    margin-top: 53.1px;
    text-align: center;
    color: white;
}

.col-1 .widget-footer {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
}

.col-2 .widget-footer {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
}

.col-3 .widget-footer {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%
}

.col-4 .widget-footer {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
}


.col-5 .widget-footer {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%
}

.col-6 .widget-footer {
    -ms-flex: 0 0 16.666666%;
    flex: 0 0 16.666666%;
    max-width: 16.666666%
}




/*Dynamic*/
.card-item{
    padding: 0 8px;
    transition: transform 300ms ease-out;
    position: relative;
}

.col-1 .card-item{
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
}

.col-2 .card-item{
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
}

.col-3 .card-item{
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
}

.col-4 .card-item{
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%
}

.col-5 .card-item{
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
}

.col-6 .card-item{
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%
}



/** Dynamic **/
.card-container {
    position: relative;
}

.col-1 .card-container {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
}

.col-2 .card-container {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
}

.col-3 .card-container {
    -ms-flex: 0 0 66.666666%;
    flex: 0 0 66.666666%;
    max-width: 66.666666%
}

.col-4 .card-container {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%
}

.col-5 .card-container {
    -ms-flex: 0 0 80%;
    flex: 0 0 80%;
    max-width: 80%;
}

.col-6 .card-container {
     -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%
}

.google-logo {
    display: flex;
    flex-wrap: wrap;
    height: 50px;
    margin-bottom: 0 !important;
}

.google-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
    text-align: center;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%
}

.google-center svg {
    width: 110px !important;
    height: 35px !important
}

.logo {
    margin: auto;
    padding: 0 !important;
    display: block !important;
    position: relative
}


.container-stars {
    margin-bottom: 3px;
    margin-top: 0px;
    display: block;
}

.star-large .star {
    width: 30px !important;
    height: 30px !important;
}

.star {
    width: 16px;
    height: 16px;
    display:inline-block;
    margin: 0 !important;
    margin-right: 1px !important;
    background-size: contain;
    background-repeat: no-repeat
}

.half {
    background-image:url('./assets/half-star.svg');
}

.full {
    background-image:url('./assets/full-star.svg');
}

.empty {
    background-image:url('./assets/empty-star.svg');
}

.rating {
    font-size: 16px;
    display: block;
    margin-top: 3px;
}

.nowrap {
    white-space: nowrap;
    display: inline-block;
    margin: 0 2px
}

.rating {
    font-size: 16px;
    display: block;
    margin-top: 3px;
}


@media (min-width: 480px) {
    .cards-wrapper {
        margin:0 20px
    }
}

.cards-wrapper {
    padding-bottom: 1px;
    overflow: hidden;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    padding-top: 5px;
}


.card-item:hover {
        -webkit-transform:translate(0, -5px);
        -ms-transform: translate(0, -5px);
        transform: translate(0, -5px)
}


.card-inner {
    height: 100%;
}

.profile-date {
    color: #000000;
    opacity: 0.5;
    font-size: 12px !important;
}

.card-item > .card-inner {
    /* border-top-width: 2px !important;
    border-bottom-width: 2px !important;
    border-left-width: 2px !important;
    border-right-width: 2px !important; */
    background-color: white !important;
    backdrop-filter: blur(0px);
    /* border-style: solid !important;
    border-color: #e5e5e5 !important; */
    padding: 15px !important;
}




.card-header:after {
    content:"";
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0px;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url(./assets/g.svg);
    background-size: 100% 100%;
}

.card-header {
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    position: relative;
}

.profile-img {
        margin: 0 15px 0 0 !important;
}

.profile {
    width: 40px !important;
    height: 40px !important;
    padding: 0px !important;
    display: block;
    border-radius: 30px;
    min-width: initial !important;
    max-width: initial !important;
    object-fit: cover;
    object-position: top;
}

.profile-details {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.widget-slider *,.widget-slider *:after {
    box-sizing:border-box;
}

.profile-name {
    font-weight: bold;
    font-size: 15px;
    overflow: hidden;
    padding-right: 25px;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #000000;
    margin-bottom: 2px;
}

.card-inner>.container-stars {
    margin-bottom: 6px;
    margin-top: 15px;
    text-align: left
}


.review-comment {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-height: 20.3px;
    height: 81.2px;
    font-size: 14px;
    text-align: left;
    transition: height 0.5s;
    font-style: normal;
    /* -webkit-line-clamp: 4; */
    overflow: hidden;
    padding-right: 0
}

.read-more {
    display: block;
    padding: 5px 0 0;
    text-align: left;
}

.read-more span {
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    font-size: 12.6px;
    color: #000000;
    opacity: 0.5;
    text-decoration: none !important;
    transition: color 300ms ease-out;
    cursor: pointer;
}

.read-more span:hover {
    opacity: 1;
    text-decoration: underline !important
}

.controls {
    display: block;
    margin-top: -12px;
    width: 100%;
    position: absolute;
    height: 0px;
    z-index: 1;
    top: 50%;
}

.controls .next, .controls .prev {
    position: absolute;
    display: inline-block;
    width: 20px;
    height: 30px;
    display: block;
    opacity: 1;
    -webkit-transition: all 200ms ease-out;
    -moz-transition: all 200ms ease-out;
    -o-transition: all 200ms ease-out;
    transition: all 200ms ease-out;
    z-index: 100;

}

.controls .next:hover, .controls .prev:hover {
    cursor: pointer;
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}

.next {
    right: 2px;
}

.dots-control {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    display: block;
}

.dots-control .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #cccccc;
    border-radius: 20px;
    padding: 4px;
    opacity: 0.4;
    margin-right: 2px;
    margin-left: 2px;
}

.dots-control .dot.active {
    opacity: 1
}

.status {
    color: white;
}

.viewMore {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    font-size: x-large;
}